"use client";

import { useUser } from "@auth0/nextjs-auth0/client";
import { MoonStars, Sun } from "@phosphor-icons/react";
import { UserProfileIcon } from "@components/AccountMenu";
import { useEffect, useCallback, useRef } from "react";
import { useAppRootContext } from "@/ctx-root/RootContext";
import { usePathname } from "next/navigation";
import { useUpdateUserThemePref, useFetchUserMetadata } from "@hooks";
import { ContentLoader } from "@components/ContentLoader";

export const AppHeader = () => {
  const { user, isLoading } = useUser();
  const { isDarkMode, setIsDarkMode, setIsFetchingPreferences } = useAppRootContext();
  const pathname = usePathname();
  const themeUpdater = useUpdateUserThemePref();
  const metadataFetcher = useFetchUserMetadata(user?.sub ?? "");

  const debounceRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // IF we're on the landing page just ignore this for now
    if (pathname === "/") {
      setIsDarkMode(false);
      return;
    }
    if (metadataFetcher.isSuccess) {
      if (!metadataFetcher.data.metadata) {
        setIsFetchingPreferences(false);
        return;
      }
      const theme = metadataFetcher.data.metadata.themePref;

      setIsDarkMode(theme === "dark");
      setIsFetchingPreferences(false);
    }
  }, [metadataFetcher.isSuccess, setIsDarkMode]);

  const toggleTheme = useCallback(() => {
    const newTheme = !isDarkMode ? "dark" : "light";
    setIsDarkMode(!isDarkMode);

    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      if (user) {
        themeUpdater.mutate([user?.sub ?? "", newTheme]);
      }
    }, 250);
  }, [isDarkMode, setIsDarkMode, user]);

  useEffect(() => {
    document.documentElement.classList.toggle("dark", isDarkMode);
  }, [isDarkMode]);

  if ((!user && !isLoading) || pathname === "/") {
    return null;
  }

  return (
    <ContentLoader>
      <div className="container relative mx-auto flex w-full flex-col items-center px-4 md:px-0">
        <div className="absolute right-0 top-2 z-10 w-full cursor-pointer pr-4 pt-3 md:pr-0">
          <UserProfileIcon user={user} />
        </div>
        <div className="absolute right-16 top-6 flex items-center space-x-4 md:right-14">
          <div className="flex items-center">
            <label className="relative inline-flex cursor-pointer items-center">
              <input type="checkbox" checked={isDarkMode} onChange={toggleTheme} className="sr-only" />
              <div className="h-[33.5px] w-14 rounded-full border bg-grey-extra-light dark:bg-gray-800">
                <div
                  className={`h-7 w-7 transform rounded-full bg-white shadow-md transition-transform dark:bg-gray-700 ${
                    isDarkMode ? "translate-x-6" : "translate-x-0.5"
                  }`}
                >
                  {isDarkMode ? (
                    <MoonStars size={20} className="mx-auto mt-0.5 translate-y-1 text-white" />
                  ) : (
                    <Sun size={20} className="mx-auto mt-0.5 translate-y-1 text-yellow" />
                  )}
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </ContentLoader>
  );
};
