import { ReactNode } from "react";
import { useAppRootContext } from "@/ctx-root/RootContext";
import { useUser } from "@auth0/nextjs-auth0/client";

export interface ContentLoaderProps {
  children: ReactNode;
}

export const ContentLoader = ({ children }: ContentLoaderProps) => {
  const { user, isLoading } = useUser();
  const { isFetchingPreferences } = useAppRootContext();

  const loadingState = isFetchingPreferences || isLoading || !user;

  if (loadingState) {
    return null;
  }

  return <>{children}</>;
};
